/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_user_files_s3_bucket": "obigae-input-bucket55855-main",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
